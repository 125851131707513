<template>
  <div class="concept-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 60%"></el-input>
      </el-form-item>
      <div class="form-block">
        <el-col class="form-block-item" :span="17">
          <el-form-item :label="$t('gpt.userAI') + '.1'" required>
            <el-select v-model="form.loraInfos[0].id" filterable style="width: 60%">
              <el-option v-for="item in loraList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- <el-input-number class="input-number" v-model="form.loraInfos[0].multiplier" :precision="1" :step="0.1"
              :min="0" :max="1"></el-input-number> -->
          </el-form-item>
          <el-form-item :label="$t('gpt.userAI') + '.2'">
            <el-select v-model="form.loraInfos[1].id" filterable style="width: 60%">
              <el-option v-for="item in loraList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- <el-input-number class="input-number" v-model="form.loraInfos[1].multiplier" :precision="1" :step="0.1"
              :min="0" :max="1"></el-input-number> -->
            <div class="notice">
              <i18n path="gpt.modelNotice" tag="div">
                <span class="brand">A,B,C,D</span>
              </i18n>
            </div>
          </el-form-item>
        </el-col>
        <el-col class="form-block-item" :span="6">
          <img class="concept-axis" :src="ossRoot + '/' + env + '/gpt/assets/concept_axis_en.png'" alt="concept" />
        </el-col>
      </div>
      <el-form-item :label="$t('gpt.face')" prop="faceId" required>
        <el-card key="target" class="target-container" shadow="none" style="width: 80%">
          <el-row :gutter="5">
            <el-col class="target-block" :span="4" v-for="item in faceTargets" :key="item.id">
              <el-row>
                <el-radio v-model="form.faceId" :label="item.id">{{ item.name }}</el-radio>
              </el-row>
              <el-row>
                <el-image class="target-img" :src="item.url | imageUrl(false)"
                  :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <pagination v-show="faceTotal > 0" :total="faceTotal" :offset.sync="faceListQuery.offset"
              :limit.sync="faceListQuery.limit" layout="prev, pager, next " @pagination="fetchFaceData" />
          </el-row>
        </el-card>
      </el-form-item>
      <!-- <el-form-item :label="$t('gpt.pose')" prop="poseId" required>
        <el-card key="target" class="target-container" shadow="none" style="width: 80%">
          <el-row :gutter="5">
            <el-col class="target-block" :span="4" v-for="item in poseTargets" :key="item.id">
              <el-row>
                <el-radio v-model="form.poseId" :label="item.id">{{ item.name }}</el-radio>
              </el-row>
              <el-row>
                <el-image class="target-img" :src="item.url | imageUrl(false)"
                  :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <pagination v-show="poseTotal > 0" :total="poseTotal" :offset.sync="faceListQuery.offset"
              :limit.sync="faceListQuery.limit" layout="prev, pager, next " @pagination="fetchFaceData" />
          </el-row>
        </el-card>
      </el-form-item> -->
      <el-form-item :label="$t('gpt.season')" required>
        <el-select v-model="form.season" filterable>
          <el-option v-for="item in seasonList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="page-tab" :label="$t('gpt.prompt')">
        <el-tabs v-model="form.promptType" type="card">
          <el-tab-pane class="tab-content" :label="$t('gpt.selfPrompt')" name="custom">
            <el-input style="width: 80%;" type="textarea" :rows="2" placeholder="" v-model="form.customPrompt">
            </el-input>
          </el-tab-pane>
          <el-tab-pane class="tab-content" :label="$t('gpt.select')" name="select">
            <el-select v-model="form.prompt" filterable>
              <el-option v-for="item in promptList" :key="item.key" :label="item.key" :value="item.key"></el-option>
            </el-select>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item class="page-tab" :label="$t('gpt.place')">
        <el-tabs v-model="form.placeType" type="card">
          <el-tab-pane class="tab-content" :label="$t('gpt.selfPrompt')" name="custom">
            <el-input style="width: 80%;" type="textarea" :rows="2" placeholder="" v-model="form.customPlace">
            </el-input>
          </el-tab-pane>
          <el-tab-pane class="tab-content" :label="$t('gpt.select')" name="select">
            <el-select v-model="form.place" filterable>
              <el-option v-for="item in placeList" :key="item.key" :label="item.key" :value="item.key"></el-option>
            </el-select>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item :label="$t('gpt.imageCount')" required>
        <el-input-number v-model="form.imageCount" :min="1" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchTargets, fetchOptions } from "@/api/gpt";
import Pagination from "@/components/Pagination";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { imageUrl } from "@/filters";
import { ossRoot } from "@/utils/server";

export default {
  components: {
    Pagination,
    AiBreadcrumb,
  },
  data() {
    return {
      ossRoot: ossRoot,
      seasonList: [],
      loraList: [],
      faceTargets: [],
      promptList: [],
      placeList: [],
      faceListQuery: {
        offset: 0,
        limit: 12,
        group: "concept-face"
      },
      faceTotal: 0,
      poseTargets: [],
      poseListQuery: {
        offset: 0,
        limit: 12,
        group: "concept-pose"
      },
      poseTotal: 0,
      form: {
        desc: "",
        loraInfos: [{
          id: null,
          multiplier: 0.5
        },
        {
          id: null,
          multiplier: 0.5
        }],
        faceId: 0,
        poseId: 0,
        season: "",
        promptType: "custom",
        customPrompt: "",
        prompt: "",
        placeType: "custom",
        customPlace: "",
        place: "",
        imageCount: 5,
      },
      loading: false,
      rules: {
        faceId: [
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error('Please select face'));
              } else {
                callback();
              }
            }, message: "Please select face", trigger: 'change'
          }
        ],
        poseId: [
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error('Please select pose'));
              } else {
                callback();
              }
            }, message: "Please select pose", trigger: 'change'
          }
        ],
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.loraInfos[0].id == null) {
            this.$message.error('Please select at least one User\'s AI');
            return
          }
          this.loading = true;
          let formCopy = JSON.parse(JSON.stringify(this.form));
          if (formCopy.loraInfos[1].id == null) {
            formCopy.loraInfos = [formCopy.loraInfos[0]];
          }
          createTask({
            ...formCopy, category: "concepter"
          })
            .then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: this.$t("common.saveSucc"),
                  message: this.$t("common.indirecting"),
                  type: "success",
                });
                let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                this.$router.push("/services/gpt-concept/concept" + suffix).catch(() => { });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    fetchLoraList() {
      this.loraList = [];
      fetchTargets({ limit: -1, group: "concept-lora" }).then(response => {
        if (response.success) {
          this.loraList = response.result && response.result.items ? response.result.items : [];
          if (this.loraList.length > 0) {
            this.form.loraInfos[0].id = this.loraList[0].id;
          }
        }
      })
    },
    fetchFaceData() {
      this.faceTargets = [];
      fetchTargets(this.faceListQuery).then(response => {
        if (response.success) {
          this.faceTargets = response.result && response.result.items ? response.result.items : [];
          this.total = response.result.totalCount;
          if (this.faceTargets.length > 0) {
            this.form.faceId = this.faceTargets[0].id;
          }
        }
      })
    },
    fetchPoseData() {
      this.poseTargets = [];
      fetchTargets(this.poseListQuery).then(response => {
        if (response.success) {
          this.poseTargets = response.result && response.result.items ? response.result.items : [];
          this.total = response.result.totalCount;
          if (this.poseTargets.length > 0) {
            this.form.poseId = this.poseTargets[0].id;
          }
        }
      })
    },
    getOptions(type) {
      let data = { "type": type }
      fetchOptions(data).then(response => {
        if (response.success) {
          if (type === "conceptSeason") {
            this.seasonList = response.result && response.result.items
              ? response.result.items : [];
            this.form.season = this.seasonList.length > 0 ? this.seasonList[0].key : "";
          } else if (type === "conceptPrompt") {
            this.promptList = response.result && response.result.items
              ? response.result.items : [];
          } else if (type === "conceptPlace") {
            this.placeList = response.result && response.result.items
              ? response.result.items : [];
          }
        }
      })
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
  },
  mounted() {
    this.fetchLoraList();
    this.fetchFaceData();
    this.fetchPoseData();
    this.getOptions("conceptSeason");
    this.getOptions("conceptPrompt");
    this.getOptions("conceptPlace");
  },
  computed: {
    env() {
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        return "staging";
      }
      if (env === "production.kr") { env = "production" }
      return env
    }
  }
};
</script>

<style lang="scss" scoped>
.concept-new {
  .page-tab {
    background-color: white;

    /deep/ .el-tabs__header {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item {
      height: 34px;
      line-height: 34px;
    }

    .tab-content {
      margin: 10px;
    }
  }

  .form {
    margin-top: 25px;

    .form-block {
      overflow: hidden;

      .form-block-item {
        float: left;

        .notice {
          margin-top: 10px;
          font-weight: bold;
          white-space: pre-line;

          .brand {
            color: orange;
          }
        }
      }
    }
  }

  .input-number {
    margin-left: 10px;
  }



  .concept-axis {
    width: 100%;
  }

  .target-container {
    /deep/.el-card__header {
      padding: 5px 15px;
    }

    /deep/.el-card__body {
      padding: 5px;
    }

    .target-block {
      text-align: center;

      .target-img {
        max-width: 80px;
      }
    }

    .pagination-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>